<template>
    <div class="names">
        <h1>{{ $t('page-title.names') }}</h1>
        <el-select v-model="region" filterable>
            <el-option value="" v-text="$t('s-names.select-default-value.region')"></el-option>
            <el-option 
                v-for="(_region, key) in regions"
                :key="key"
                :value="_region"
                :label="capitalize(_region)"
            ></el-option>
        </el-select>
        <span class="space"></span>
        <el-select v-model="gender">
            <el-option value="" v-text="$t('s-names.select-default-value.gender')"></el-option>
            <el-option 
                v-for="(_gender, key) in genders"
                :key="key"
                :value="_gender"
                :label="capitalize(_gender)"
            ></el-option>
        </el-select>
        <span class="space"></span>
        <el-select v-model="limit">
            <el-option value="0" :label="$t('s-names.select-default-value.limit')"></el-option>
            <el-option 
                v-for="(_limit, key) in limits"
                :key="key"
                :value="_limit"
                :label="_limit"
            ></el-option>
        </el-select>
        <div style="margin-top: 0.7rem;">
            <el-checkbox type="checkbox" v-model="include_surname" id="include_surname">
                {{ $t('s-names.cb-include-surname') }}
                <el-popover
                    placement="bottom-start"
                    :title="$t('s-names.surname-popover-title')"
                    :width="250"
                    trigger="hover"
                    :content="$t('s-names.surname-popover-content')"
                >
                    <template #reference>
                        <sup>?</sup>
                    </template>
                </el-popover>
            </el-checkbox>
        </div>
        <transition name="fade" mode="out-in">
            <div class="names-output" v-if="firstNames && firstNames.length > 0">
                <el-divider content-position="left">
                    {{ $t('s-names.divider-text') }}
                </el-divider>
                <display-names
                    :firstNames="firstNames"
                    :surnames="surnames"
                    :region="region"
                    :limit="limit"
                    :include_surname="include_surname"
                />
            </div>
        </transition>
    </div>
</template>

<script>
import { capitalize } from "@/utils/text"
import DisplayNames from '@/components/DisplayNames.vue'
import Settings from '@/settings/names'
import NamesMixin from '@/mixins/names'

export default {
    components: { 
        DisplayNames,
    },

    mixins: [ NamesMixin ],

    data(){
        return {
            Settings,
            region: Settings.DEFAULT_COUNTRY,
            gender: Settings.DEFAULT_GENDER,
            limits: Settings.LIMITS,
            limit: Settings.DEFAULT_NUMBER,
            include_surname: Settings.DEFAULT_ADD_SURNAME,
        }
    },

    methods: {
        capitalize,
    },
}
</script>

<style>

</style>